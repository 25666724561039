import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import Layout from "./pages/layout/layout";
import Profile from "./pages/profile/profile";
import Directory from "./pages/directory/directory";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import RfidManagement from "./pages/rfid/rfidManagement";
import Visitors from "./pages/visitors/visitors";
import VisitorEdit from "./pages/visitors/visitorEdit";
import VisitorInfo from "./pages/visitors/visitorInfo";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Staffs from "./pages/staffs/staffs";
import Datagrid from "./components/common/datagrid";
import BusManagement from "./busChaperons";
import ImageManagement from "./images";
import CheckIn from "./checkIn";
import CheckOut from "./checkOut";
import Manual from "./manualCheckIn";
import ManualOut from "./manualCheckOut";
import Reports from "./reports";
import ReportsOut from "./reportsOut";
import ReportsMS from "./reportsms";
import ReportsES from "./reportses";
import ReportsHS from "./reportshs";
import ErrorLogs from "./ErrorLogs";
import DecalRequests from "./pages/decal/DecalRequests";
import SelfAttestation from "./pages/decal/SelfAttestation";
import AttestationForm from "./pages/decal/AttestationForm";
import ViewAttestation from "./pages/decal/ViewAttestation";
import StaffEdit from "./pages/staffs/staffEdit";
import EditUser from './EditUser';
import ManualCheckOut from "./manualCheckOut";
const theme = createTheme({
  palette: {
    primary: {
      main:"#2E3192",
    }
  },
  typography: {
    fontFamily: "Ubuntu",
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color:"#000",
          // boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)" 
        },
        
      },
      
    },
  },
});
export default function MyApp() {

  

  return (
    <div>
      <GoogleOAuthProvider clientId="358122337447-oroe7v76e3da5v715vuv88kqrsj32cq3.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Login />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="directory" element={<Directory />} />
              {/*<Route path="rfid" element={<RfidManagement />} />
              <Route path="rfid-checkout" element={<RfidManagement ischeckout={true}/>} />*/}
              <Route path="preregister/:id" element={<VisitorEdit preRegister />} />
              <Route path="qrcheckin" element={<RfidManagement qrCheckin={true}/>} />
              <Route path="visitors" element={<Visitors />} />
              <Route path="visitor/:id" element={<VisitorEdit />} />
              <Route path="visitor/view/:id" element={<VisitorInfo />} />
              <Route path="staffs" element={<Staffs />} />
              <Route path="staff/:id" element={<StaffEdit />} />
              <Route path="reports" element={<Reports/>}/>
              <Route path="bus" element={<BusManagement/>}/>
              <Route path="decal" element={<DecalRequests/>}/>
              <Route path="self-attestation" element={<SelfAttestation/>}/>
              <Route path="att-form/:id" element={<AttestationForm/>}/>
              <Route path="view-att/:id" element={<ViewAttestation/>}/>
              {/* <Route path="staffs" element={<Directory />} /> */}
              <Route path="login" element={<Login />} />
              <Route path="check-in" element={<CheckIn />} />
              <Route path="manual" element={<Manual />} />
              <Route path="manualout" element={<ManualOut />} />
              <Route path="check-out" element={<CheckOut />} />
              <Route path="/edit-user/:id" element={<EditUser />} />
              <Route path="/images" element={<ImageManagement />} />
              <Route path="/errorlogs" element={<ErrorLogs />} />
              <Route path="/reportsms" element={<ReportsMS />} />
              <Route path="/reportses" element={<ReportsES />} />
              <Route path="/reportshs" element={<ReportsHS />} />
              <Route path="/reportsout" element={<ReportsOut />} />

              {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
